var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      _vm._l(_vm.assessPlan.processes, function (process, idx) {
        return _c("q-chip", {
          key: idx,
          attrs: {
            outline: "",
            square: "",
            removable: false,
            color: process.selected1 ? "primary" : "grey-6",
            "text-color": "white",
            icon: "memory",
            selected: process.selected1,
            label: process.processName,
            title: process.processName,
          },
          on: {
            "update:selected": [
              function ($event) {
                return _vm.$set(process, "selected1", $event)
              },
              (state) => _vm.selectedProcess(state, process, idx),
            ],
          },
          model: {
            value: process.check1,
            callback: function ($$v) {
              _vm.$set(process, "check1", $$v)
            },
            expression: "process.check1",
          },
        })
      }),
      1
    ),
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c(_vm.processComponent, {
          tag: "component",
          attrs: {
            assessPlan: _vm.assessPlan,
            process: _vm.process,
            disabled: _vm.disabled,
            updateBtnData: _vm.updateBtnData,
            vendorFlag: _vm.vendorFlag,
          },
          on: {
            "update:assessPlan": function ($event) {
              _vm.assessPlan = $event
            },
            "update:assess-plan": function ($event) {
              _vm.assessPlan = $event
            },
            "update:process": function ($event) {
              _vm.process = $event
            },
            "update:disabled": function ($event) {
              _vm.disabled = $event
            },
            "update:updateBtnData": function ($event) {
              _vm.updateBtnData = $event
            },
            "update:update-btn-data": function ($event) {
              _vm.updateBtnData = $event
            },
            "update:vendorFlag": function ($event) {
              _vm.vendorFlag = $event
            },
            "update:vendor-flag": function ($event) {
              _vm.vendorFlag = $event
            },
            saveProcess: _vm.saveProcess,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }