<template>
  <div class="row">
    <div class="col-12">
      <q-chip
        v-for="(process, idx) in assessPlan.processes"
        :key="idx"
        outline square
        :removable="false"
        :color="process.selected1 ? 'primary' : 'grey-6'"
        text-color="white"
        icon="memory"
        :selected.sync="process.selected1"
        :label="process.processName"
        :title="process.processName"
        v-model="process.check1"
        @update:selected="state => selectedProcess(state, process, idx)"
      />
    </div>
    <div class="col-12">
      <component
        :is="processComponent"
        :assessPlan.sync="assessPlan"
        :process.sync="process"
        :disabled.sync="disabled"
        :updateBtnData.sync="updateBtnData"
        :vendorFlag.sync="vendorFlag"
        @saveProcess="saveProcess"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'riskTargetProcess',
  props: {
    assessPlan: {
      type: Object,
      default: () => ({
        ramStepCd: '',
        processes: [], // 대상공정 목록
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        plantCd: '',  // 사업장 코드
        ramTechniqueCd: null,  // 평가기법 코드_HAZOP/4m/JSA/CHECKLIST
        ramAssessTypeCd: null,  // 평가구분_최초/정기/수시
        assessmentName: '',  // 평가명
        assessmentStartDate: '',  // 평가시작일
        assessmentEndDate: '',  // 평가종료일
        assessmentPeriod: [],
        assessmentYear: '',  // 평가년도
        assessmentManageDeptCd: '',  // 평가주관 부서 코드
        assessmentManageUserId: '',  // 평가주관 담당자 ID
        ramMatrixId: null,  // 위험메트릭스 번호
        sopMocId: '',  // MOC 일련번호
        remark: '',  // 상세내용
        workArea: '',  // 작업지역
        regUserId: '',  // 작성자 ID
        regUserName: '',  // 작성자 ID
        regDtStr: '',
        reviewUserId: '',  // 검토자 ID
        reviewDate: '',  // 검토일
        approvalUserId: '',  // 승인자 ID
        approvalDate: '',  // 승인일
        vendorCd: '',
        sopConstructionId: '',
        chgUserId: '',  // 수정자 ID
        teams: [], // 평가팀 목록
        occasions: [], // 수시 변경/추가 내역 목록
        conferences: [], // 회의 목록
        relatedDocuments: [], // 관련자료 목록
      }),
    },
    searchFlag: {
      type: Object,
      default: () => ({
        value: '',
      }),
    },
    updateBtnData: {
      type: Object,
      default: () => ({
        flag: false,
        research: '',
      }),
    },
    vendorFlag: {
      type: Boolean,
      default: () => ({
        flag: false,
      }),
    },
  },
  data() {
    return {
      process: {
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        assessUserName: '',  // 평가 담당자
        workCycleCd: null,  // 작업발생주기코드
        workingTime: 0,  // 작업시간(1회)
        vendorFlag: 0,
        workMeasurementPlanId: '',  // 계획 일련번호
        temperature: '',  // 사용온도
        measPlanContent: '',
        estimationFlag: '',  // CHARM 추정 완료여부
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        equipments: [],
        sops: [],
        drawNodes: [],
        draws: [],
        fmScenarios: [],
        hazopScenarios: [],
        scenarios: [],
        charmResultScenarios: [],
        charmEstimationScenarios: [],
        targets: [],
        thirdScenarios: [],
      },
      saveProcessCd: '',
      processComponent: () => import(`${'./riskTargetProcessDetail.vue'}`),
      // tab: 'process',
      // tabItems: [
      //   { name: 'process', icon: 'work_outline', label: '대상공정', component: () => import(`${'./riskTargetProcessDetail.vue'}`) },
      // ],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      editable: true,
      saveUrl: '',
      deleteUrl: '',
    };
  },
  computed: {
    disabled() {
      return Boolean(this.assessPlan.ramStepCd !== 'RRS0000001' 
        && this.assessPlan.ramStepCd) 
        && !this.updateBtnData.flag
    }
  },
  watch: {
    'assessPlan.processes': {
      handler: function () {
        let idx = this.$_.findIndex(this.assessPlan.processes, { processCd: this.saveProcessCd })
        if (this.saveProcessCd && idx > -1) {
          this.assessPlan.processes[idx].selected1 = true

          this.assessPlan.processes.forEach((item, index) => {
            if (idx !== index) {
              this.$set(item, 'selected1', false)
            }
          })
        }
      },
      deep: true,
    },
    'searchFlag.value': {
      handler: function () {
        if (this.assessPlan.processes && this.assessPlan.processes.length > 0) {
          let idx = this.$_.findIndex(this.assessPlan.processes, { processCd: this.saveProcessCd })
          if (idx === -1) {
            this.selectedProcess(true, this.assessPlan.processes[0], 0)
          }
        }
      },
      deep: true,
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // code setting
      // list setting
      if (this.assessPlan.processes && this.assessPlan.processes.length > 0) {
        this.selectedProcess(true, this.assessPlan.processes[0], 0)
      }
    },
    /**
     * chip 라이브러리 사용
     * 공정을 클릭 했을 시에 해당 공정이 클릭되었음을 표시하게끔 배경색을 변경시키며
     * 다른 공정들은 selected false 처리 및 색상 primary로 변경
     */
    selectedProcess(state, process, idx) {
      if (this.saveProcessCd) {
        this.saveProcessCd = ''
      }
      if (state) {
        this.assessPlan.processes.forEach((item, index) => {
          if (idx !== index) {
            this.$set(item, 'selected1', false)
          } else {
            this.$set(item, 'selected1', true)
            this.process = process;
          }
        })
      } else {
        this.$set(this.assessPlan.processes[idx], 'selected1', true)
      }
    },
    saveProcess(processCd) {
      this.saveProcessCd = processCd;
      this.$emit('saveCallback', this.assessPlan.ramRiskAssessmentPlanId)
    },
  }
};
</script>
